import * as React from 'react'

export const ShidenCircle = (props: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72" {...props}>
    <g fill="none" fillRule="nonzero">
      <path
        fill="#BBA5E1"
        d="M31.64 1.438c-.544.1-.983.194-1.432.262-2.76.405-5.412 1.202-8.008 2.208A34.124 34.124 0 0018.13 5.8c-1.942 1.068-3.86 2.177-5.62 3.538-1.397 1.085-2.66 2.308-3.663 3.774a9.73 9.73 0 00-1.634 4.108c-.26 1.733.066 3.367.742 4.944.76 1.778 1.873 3.322 3.222 4.698l.846.86c-.139.1-.191-.01-.26-.056a21.116 21.116 0 01-3.111-2.784c-1.172-1.257-2.135-2.66-2.75-4.283a8.133 8.133 0 01-.52-3.68c.11-1.187.426-2.347.932-3.426a15.393 15.393 0 012.31-3.538 20.748 20.748 0 013.811-3.39c2.29-1.619 4.783-2.832 7.388-3.862A53.997 53.997 0 0126.18.66c1.038-.285 2.094-.5 3.162-.64.13-.016.246-.05.366.031.631.429 1.242.882 1.932 1.386zM23.946 18.103c.26.193.512.384.767.578a18.204 18.204 0 013.99 4.07c.8 1.151 1.432 2.392 1.747 3.78a8.369 8.369 0 01.04 3.555c-.439 2.14-1.45 3.99-2.83 5.656-1.7 2.05-3.776 3.62-6.049 4.966-1.896 1.126-3.907 2.017-5.956 2.8a52.585 52.585 0 01-6.226 1.933c-.92.232-1.852.415-2.791.548a.518.518 0 01-.408-.085c-.59-.429-1.185-.853-1.794-1.29.103-.156.246-.094.355-.124.123-.037.248-.065.374-.084 1.59-.22 3.164-.551 4.71-.99a40.634 40.634 0 005.368-1.911c1.64-.73 3.23-1.566 4.76-2.504 1.17-.683 2.301-1.432 3.387-2.243 1.623-1.25 3.079-2.665 4.145-4.44a9.32 9.32 0 001.248-3.39c.26-1.684.02-3.295-.615-4.857a14.536 14.536 0 00-2.274-3.756c-.52-.642-1.113-1.22-1.693-1.81-.108-.11-.21-.228-.314-.342l.059-.06z"
        transform="translate(-187 -1180) translate(187 1180) translate(18 13)"
      />
      <path
        fill="#764BC4"
        d="M27.471 24.06c.1.066.165.171.181.29.421 1.399.557 2.814.218 4.251a6.268 6.268 0 01-.82 1.926c-1.47 2.331-3.342 4.282-5.524 5.942-2.5 1.903-5.25 3.348-8.164 4.496a47.682 47.682 0 01-8.856 2.517c-.108.02-.217.032-.325.049-1.032.16-.743.255-1.694-.435-.326-.237-.639-.494-.957-.745-.083-.065-.182-.121-.195-.242.087-.131.225-.12.348-.145 2.202-.47 4.39-.999 6.546-1.651 3.11-.941 6.144-2.073 9.02-3.605 2.054-1.105 4.008-2.38 5.73-3.965 1.389-1.278 2.585-2.711 3.453-4.403a9.875 9.875 0 00.955-2.695c.06-.346.088-.697.084-1.048.002-.17 0-.345 0-.536zM34.684 3.902c-.084.123-.22.116-.343.143-2.182.473-4.359.992-6.493 1.636-3.055.917-6.04 2.029-8.874 3.516-2.036 1.068-3.962 2.3-5.685 3.839-1.35 1.205-2.536 2.551-3.44 4.134a10.21 10.21 0 00-1.246 3.343c-.047.31-.069.622-.065.935v.49a.775.775 0 01-.176-.287 8.278 8.278 0 01-.238-4.146c.164-.738.46-1.44.874-2.073 1.498-2.358 3.405-4.324 5.625-5.993a32.669 32.669 0 016.66-3.827 47.243 47.243 0 019.69-2.986c.465-.093.938-.155 1.406-.24.235-.05.48.012.665.166.491.384.991.756 1.487 1.135a.297.297 0 01.153.215z"
        transform="translate(-187 -1180) translate(187 1180) translate(18 13)"
      />
      <path
        fill="#764BC4"
        d="M0 40.952c0-.059.008-.118.023-.174.343-.692.693-1.381 1.032-2.076a.52.52 0 01.431-.287c1.274-.249 2.55-.492 3.82-.765 3.705-.8 7.272-1.989 10.612-3.816a33.122 33.122 0 005.551-3.754 14.683 14.683 0 002.75-2.994c.66-.985 1.173-2.037 1.384-3.22.14-.787.18-1.573-.089-2.345a.824.824 0 01.008-.183c.48.934.885 1.824 1.042 2.805.05.366.031.738-.056 1.097a9.49 9.49 0 01-1.344 3.125c-1.023 1.58-2.354 2.86-3.816 4.015-3.33 2.63-7.033 4.57-10.987 6.048a48.02 48.02 0 01-9.005 2.397c-.438.073-.885.203-1.356.127zM10.374 24.002a4.684 4.684 0 01-.009-1.595c.188-1.284.717-2.429 1.433-3.492.958-1.416 2.176-2.584 3.516-3.623 4.048-3.144 8.584-5.253 13.53-6.5 1.865-.47 3.75-.843 5.64-1.194a.652.652 0 00.52-.406c.296-.613.607-1.22.912-1.834.047-.096.124-.188.06-.31a1.018 1.018 0 00-.54 0c-.687.105-1.375.207-2.057.342a48.902 48.902 0 00-10.76 3.422 38.028 38.028 0 00-8.5 5.24c-1.284 1.056-2.433 2.239-3.325 3.655a9.194 9.194 0 00-1.32 3.195c-.082.416-.073.845.025 1.258.181.713.44 1.405.773 2.06.03.066.13.311.217.324l-.115-.542z"
        transform="translate(-187 -1180) translate(187 1180) translate(18 13)"
      />
      <path
        fill="#5928B1"
        d="M23.843 19.09a5.589 5.589 0 011.013 3.668c-.13 1.841-1.009 3.32-2.22 4.63-.839.906-1.805 1.672-2.782 2.416a37.375 37.375 0 01-12.94 6.358 33.37 33.37 0 01-4.516.905 5.417 5.417 0 01-.438.034.626.626 0 01-.147-.03c-.037-.131.052-.218.108-.308.307-.5.633-.992.922-1.503a.897.897 0 01.689-.465 30.159 30.159 0 003.357-.822c4.514-1.357 8.654-3.472 12.492-6.207 1.158-.824 2.229-1.733 3.082-2.882a8.181 8.181 0 001.398-2.802c.23-.837.257-1.718.076-2.568-.031-.141-.064-.283-.094-.424zM34.2 8.907a.234.234 0 01-.037.233c-.358.578-.728 1.15-1.068 1.738-.143.249-.367.283-.597.327-1.15.225-2.287.507-3.409.847-4.511 1.361-8.649 3.48-12.484 6.219-1.111.786-2.143 1.665-2.973 2.76-.8 1.055-1.393 2.202-1.605 3.526a5.258 5.258 0 00.074 2.028l.065.277c-.18-.018-.207-.158-.26-.262-.979-1.87-1.02-3.764-.122-5.679a9.334 9.334 0 012.344-3.033c4.863-4.291 10.48-7.076 16.786-8.483a28.526 28.526 0 012.758-.469c.177-.022.358-.021.527-.029z"
        transform="translate(-187 -1180) translate(187 1180) translate(18 13)"
      />
    </g>
  </svg>
)
