import * as React from 'react'

export const AcalaCircle = (props: React.ComponentProps<'svg'>) => (
  <svg
    viewBox="0 0 88 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M43.6965 49.5651C41.9108 49.5651 40.1744 49.714 38.4945 49.9987L41.0816 45.5178C41.9549 45.4602 42.8293 45.4311 43.6965 45.4311C45.7279 45.4311 47.7949 45.5987 49.7933 45.9193L39.7478 28.52L23.0252 57.4845L20.6353 53.3451L39.7102 20.3065L39.7495 20.3746L39.7871 20.3094L62.8067 60.1805H58.0269L52.6468 50.8618C49.8436 50.0164 46.8468 49.5651 43.6965 49.5651Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M64.2943 57.5542L42.0076 18.9526H46.7873L66.6841 53.4149L64.2943 57.5542Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M42.1743 37.997L33.0231 53.8475C36.2974 52.8596 40.152 52.4185 43.7884 52.4185C44.0823 52.4185 44.377 52.422 44.6722 52.429C46.7381 52.4778 48.825 52.6964 50.8248 53.0697L53.8779 58.3579C50.7525 57.2211 47.3696 56.5524 43.7884 56.5524C38.4919 56.5524 33.6277 57.8624 29.3915 60.1414L29.4581 60.0221L29.3668 60.1804H24.587L39.7845 33.8577L42.1743 37.997Z"
      fill="url(#paint2_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44 80.8163C64.3331 80.8163 80.8163 64.3331 80.8163 44C80.8163 23.6669 64.3331 7.18367 44 7.18367C23.6669 7.18367 7.18367 23.6669 7.18367 44C7.18367 64.3331 23.6669 80.8163 44 80.8163ZM44.0898 76.3265C61.9928 76.3265 76.5061 61.8132 76.5061 43.9102C76.5061 26.0072 61.9928 11.4939 44.0898 11.4939C26.1868 11.4939 11.6735 26.0072 11.6735 43.9102C11.6735 61.8132 26.1868 76.3265 44.0898 76.3265Z"
      fill="url(#paint3_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44 88C68.3005 88 88 68.3005 88 44C88 19.6995 68.3005 0 44 0C19.6995 0 0 19.6995 0 44C0 68.3005 19.6995 88 44 88ZM44.1796 83.8694C66.1988 83.8694 84.049 66.0193 84.049 44C84.049 21.9807 66.1988 4.13061 44.1796 4.13061C22.1603 4.13061 4.3102 21.9807 4.3102 44C4.3102 66.0193 22.1603 83.8694 44.1796 83.8694Z"
      fill="url(#paint4_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1={132.24}
        y1={57.577}
        x2={65.1457}
        y2={-20.9227}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#645AFF" />
        <stop offset={0.5238} stopColor="#E40C5B" />
        <stop offset={1} stopColor="#FF4C3B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1={132.24}
        y1={57.577}
        x2={65.1457}
        y2={-20.9227}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#645AFF" />
        <stop offset={0.5238} stopColor="#E40C5B" />
        <stop offset={1} stopColor="#FF4C3B" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1={132.24}
        y1={57.577}
        x2={65.1457}
        y2={-20.9227}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#645AFF" />
        <stop offset={0.5238} stopColor="#E40C5B" />
        <stop offset={1} stopColor="#FF4C3B" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1={132.24}
        y1={57.577}
        x2={65.1457}
        y2={-20.9227}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#645AFF" />
        <stop offset={0.5238} stopColor="#E40C5B" />
        <stop offset={1} stopColor="#FF4C3B" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1={132.24}
        y1={57.577}
        x2={65.1457}
        y2={-20.9227}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#645AFF" />
        <stop offset={0.5238} stopColor="#E40C5B" />
        <stop offset={1} stopColor="#FF4C3B" />
      </linearGradient>
    </defs>
  </svg>
)
