import * as React from 'react'

export const KaruraCircle = (props: React.ComponentProps<'svg'>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <rect
      width="40"
      height="40"
      fill="url(#paint0_linear_22857_22359)"
      rx="20"
    ></rect>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M18.105 15.698l6.559-5.958h5.257l-8.623 7.944L29.92 29.53h-4.412l-3.99-5.512s.715 2.317.836 3.427c.15 1.375-.026 2.486-.462 3.595.461-3.09-1.032-5.548-2.172-7.425-.662-1.09-1.205-1.985-1.177-2.693.095-2.38 1.799-4.043 3.053-5.055 0 0-.786-.76-2.014-.242-1.227.523-1.69 1.151-1.69 1.151s-.806-.048-1.656.54c-.594.41-1.426 1.653-2.214 2.831-.337.504-.667.996-.966 1.406-.945 1.294-1.443.879-1.497.833.033.12.47 1.448 2.218.204 0 0 .538.713-.45 1.655 0 0 1.389-.071 1.454-1.4 0 0 .312.504.388 2.442.034.848-.141 2.09-.491 2.718a.035.035 0 00-.007.01l-.004.007a2.99 2.99 0 01-2.61 1.51H10.13V9.741h3.534l.007 6.925 1.954-.005a3.708 3.708 0 002.48-.963zm-1.65 3.064s0-.144.283-.614c.332-.549.935-.72.974-.73l.002-.001c-.268 1.177-1.259 1.345-1.259 1.345z"
      clipRule="evenodd"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear_22857_22359"
        x1="41.91"
        x2="84.403"
        y1="122"
        y2="99.38"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF4C3B"></stop>
        <stop offset="1" stopColor="#E40C5B"></stop>
      </linearGradient>
    </defs>
  </svg>
)
