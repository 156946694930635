import * as React from 'react'

export const PolkadotNaked = (props: React.ComponentProps<'svg'>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill="#E6007A"
      d="M23.906 46.97c4.493 0 8.135-2.142 8.135-4.785 0-2.643-3.642-4.785-8.135-4.785s-8.135 2.142-8.135 4.785c0 2.643 3.642 4.785 8.135 4.785zM43.865 35.486c2.284-3.962 2.27-8.253-.033-9.584-2.302-1.33-6.02.802-8.303 4.764-2.284 3.962-2.27 8.252.032 9.583 2.302 1.331 6.02-.802 8.304-4.764zM23.906 10.6c4.493 0 8.135-2.142 8.135-4.785 0-2.643-3.642-4.786-8.135-4.786S15.77 3.172 15.77 5.815s3.642 4.785 8.135 4.785zM12.472 17.334c2.284-3.962 2.27-8.252-.033-9.583-2.302-1.331-6.02.802-8.303 4.763-2.284 3.962-2.27 8.253.032 9.584 2.302 1.33 6.02-.802 8.303-4.764zM12.44 40.249c2.301-1.331 2.316-5.622.032-9.584-2.284-3.961-6.002-6.094-8.304-4.763-2.302 1.33-2.316 5.621-.032 9.583 2.284 3.962 6.001 6.095 8.303 4.764zM43.832 22.098c2.302-1.331 2.317-5.622.033-9.583C41.58 8.553 37.863 6.42 35.56 7.75c-2.302 1.33-2.316 5.621-.032 9.583 2.284 3.962 6.001 6.095 8.303 4.764z"
    ></path>
  </svg>
)
