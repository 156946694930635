import * as React from 'react'

export const KusamaNaked = (props: React.ComponentProps<'svg'>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#1B2026"
      d="M0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12z"
    ></path>
    <path
      fill="#fff"
      d="M19.281 7.647c-.247-.188-.545-.446-1.083-.514-.507-.064-1.021.263-1.37.48-.348.217-1.007.856-1.281 1.048-.274.193-.97.374-2.09 1.024-1.12.65-5.526 3.384-5.526 3.384l1.145.012-5.102 2.553h.51l-.734.542s.647.169 1.194-.169l.002.153s6.08-2.32 7.258-1.717l-.715.204c.063 0 1.22.072 1.22.072s.037.699.734 1.144c.697.446.71.687.71.687s-.361.144-.361.325c0 0 .535-.157 1.033-.145.498.012.933.145.933.145s-.037-.193-.523-.325c-.485-.132-.958-.638-1.194-.916a1.26 1.26 0 01-.198-1.264c.168-.419.75-.651 1.952-1.252 1.419-.71 1.742-1.241 1.941-1.65.2-.41.498-1.229.663-1.614.21-.494.47-.759.681-.915.212-.157 1.17-.506 1.17-.506s-.73-.606-.969-.786z"
    ></path>
  </svg>
)
