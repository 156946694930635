import * as React from 'react'

export const KintsugiCircle = (props: React.ComponentProps<'svg'>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 64 64"
    {...props}
  >
    <g clipPath="url(#clip0_21099_151256)">
      <rect width="64" height="64" fill="#051433" rx="32"></rect>
      <path
        fill="#DADADA"
        d="M13.44 18.32a32.897 32.897 0 01-3.76-.186c-2.59-.306-3.934-.955-4.224-2.048-.467-1.714 2.026-3.155 3.943-4.096 7.296-3.559 23.297-7.251 35.67-8.232 3.826-.303 7.04-.322 9.308-.056 2.59.306 3.934.955 4.224 2.048.468 1.714-2.026 3.155-3.943 4.096-7.296 3.558-23.296 7.251-35.67 8.231a70.564 70.564 0 01-5.547.244zM45.127 4.535l.06.768c-12.214.97-27.968 4.591-35.118 8.08-3.072 1.498-3.137 2.303-3.135 2.303.103.374 2.432 1.614 11.935.86 12.214-.967 27.968-4.594 35.118-8.082 3.072-1.499 3.137-2.297 3.135-2.304-.103-.373-2.426-1.614-11.935-.86l-.06-.765zm-31.739 55.94a32.919 32.919 0 01-3.76-.186c-2.59-.306-3.934-.955-4.225-2.048-.473-1.709 2.021-3.15 3.941-4.086 7.296-3.558 23.297-7.251 35.67-8.231 3.826-.304 7.04-.323 9.308-.057 2.59.306 3.934.955 4.23 2.048.468 1.714-2.026 3.155-3.943 4.096-7.296 3.557-23.296 7.251-35.67 8.232-2.043.151-3.909.233-5.551.233zm31.684-13.786l.06.768c-12.214.968-27.968 4.594-35.118 8.082-3.072 1.499-3.137 2.298-3.135 2.304.103.374 2.426 1.614 11.935.86 12.214-.969 27.968-4.594 35.118-8.082 3.072-1.499 3.137-2.304 3.135-2.304-.103-.374-2.426-1.614-11.935-.86l-.06-.768zm-30.399-6.906a27.401 27.401 0 004.992 3.826l-.744 1.225a26.961 26.961 0 01-6.45-5.291l.021 5.226h-1.406V30.56h1.356l.022 6.784c.416.503.83 1.006 1.246 1.466l2.953-3.106h1.898l-3.888 4.078z"
      ></path>
      <path
        fill="url(#paint0_linear_21099_151256)"
        d="M42.261 22.837a12.748 12.748 0 00-8.975-3.669h-.134a12.831 12.831 0 00.128 25.661h.128a12.83 12.83 0 008.853-21.992z"
      ></path>
      <path
        fill="#DADADA"
        d="M38.759 21.285c-1.504-.384-2.713-.072-3.694.937a4.676 4.676 0 00-.34.403c-.257.324-.474.604-.971.72a6.589 6.589 0 01-2.035.084 6.412 6.412 0 01-.5-.098c-.512-.116-.811-.185-1.417.391-.189.192-.36.4-.511.624-.27.37-.523.719-.94.879a2.116 2.116 0 01-.599.128l-.055-.024-.013.026a4.995 4.995 0 01-.88-.056 5.905 5.905 0 00-.692-.061c.316-.459.591-.943.825-1.448.423-.065.784-.343 1.134-.616.184-.151.38-.288.586-.41.7-.383 1.04-.71 1.647-1.377.576-.63 1.195-1.024 1.946-1.419.375-.192.702-.467.953-.805 2.505.018 4.85.603 6.912 1.965-.217.138-.683.326-1.356.157z"
      ></path>
      <path
        fill="#DADADA"
        d="M45.712 30.208a3.463 3.463 0 01-.512-.248 2.433 2.433 0 00-.832-.33l-.748-.368c-1.18-.204-1.664-.05-2.856-.384a5.271 5.271 0 00-1.704-.211l-.03-.025-.02.027c-.41.018-.82.076-1.28.14l-.317.045c-.128.016-.271.04-.434.065-1.016.16-2.716.429-3.306-.233l-.034.03a5.887 5.887 0 00-2.985-.812c-1.635 0-2.149-.442-3.082-1.244a35.348 35.348 0 00-.53-.45h.256c.424.15.87.227 1.32.228.085 0 .171-.003.256-.012.234-.026.452-.13.617-.297.074-.064.151-.124.232-.178a1.06 1.06 0 01.6-.146c.09 0 .186-.008.288-.02.444-.056.756-.304 1.057-.545.282-.222.546-.434.907-.491.09-.016.18-.022.275-.03.239.002.474-.055.686-.166a1.69 1.69 0 00.312-.256c.109-.124.242-.224.39-.294.474-.163.684-.553.939-.877.112-.144.64-.22.782-.365 1.066-1.097 1.92-1.487 3.553-1.074.832.209 1.152.247 1.431.043a12.828 12.828 0 015.06 8.576 2.343 2.343 0 01-.29-.098zM29.474 41.301a1.229 1.229 0 01-.023-.242 2.977 2.977 0 00-.062-.561c-.089-.43-.212-.512-.366-.623a1.166 1.166 0 01-.33-.322 2.475 2.475 0 01-.335-.768l-.011-.036c.112.071.229.136.348.195.057.03.128.07.217.116.332.18.788.428 1.024.407a.79.79 0 00.505-.286c.107-.108.454.096.603.069.272-.049.588-.128.998.04.173.07.323.128.436.167.214.067.432.159.64.246.346.162.71.281 1.085.355.208.028.418.04.628.038.39-.023.778.06 1.125.238.803.4.976.433 1.851.347.657-.065 1.491-.128 2.127-.024.602.097 1.153.377 1.689.727 0 .053.08.235.086.288a12.006 12.006 0 01-8.93 3.133 2.76 2.76 0 00-.393-.348 2.346 2.346 0 00-.541-.246c-.25-.09-.512-.182-.65-.339-.2-.22-.178-.466-.158-.704a.895.895 0 00-.04-.48c-.117-.243-.375-.393-.647-.55a2.94 2.94 0 01-.458-.305 1.605 1.605 0 01-.361-.397c.009-.04-.057-.136-.057-.136zm-3.157-19.43c-.023.325-.005.653.052.974.059.427.114.83-.049 1.29l-.016.045h-.016v.034c-.215.55-.72 1.33-.958 1.686-.543-.073-.795-.478-1.06-.905a2.928 2.928 0 00-.614-.775 3.377 3.377 0 00-.384-.256 12.905 12.905 0 013.278-2.894c-.123.252-.201.522-.233.8z"
      ></path>
      <path
        fill="#DADADA"
        d="M42.367 40.922c-.019-.256-.031-.525-.034-.794-.014-1.408-.08-1.87-1.024-2.661-.726-.612-1.563-.914-1.83-1.827-.148-.504-.151-1.152-.155-1.828 0-.298 0-.599-.018-.896.07-.29.174-.57.31-.835.16-.346.416-.704.362-1.08-.045-.316 0-.09-.137-.513-.12-.372-.346-.58-.613-.797a5.09 5.09 0 011.492.21c1.196.341 2.314-.105 3.5.098l.119.02c.28.057.546.163.787.316.168.1.344.185.526.256.118.042.24.074.362.097.064.434.096.873.096 1.312a12.792 12.792 0 01-3.663 8.977l-.08-.055zM21.85 35.758c.111.038.22.075.32.114.446.152.87.36 1.265.617.416.285.74.568.979 1.024.256.488.447.803.962 1.073l.154.08c.64.33 1.09.567 1.422 1.209.201.383.566.549.918.703.192.078.376.173.55.285l.07.048.129.085c.055.174.18.34.489.61.165.135.343.254.53.355.284.165.551.32.664.55.05.148.06.306.03.458-.025.282-.053.6.212.896.183.203.486.312.78.416.198.062.389.148.567.257.106.078.207.163.301.256a12.84 12.84 0 01-11.312-9.488c.264.208.637.338.97.452zm2.044-10.43c.22.353.447.713.846.884-.366.063-.483-.039-.869.29-.204.173-.647.144-.833.342-.316.335-.392.918-.791 1.128a9.02 9.02 0 01-1.319.536c.461-1.633 1.024-2.854 2.049-4.153.12.068.234.146.341.234.232.212.427.461.576.738z"
      ></path>
      <path
        fill="#DADADA"
        d="M40.118 37.533l.084.072c.835.7 1.06.89 1.071 2.102 0 .211.01.42.025.627-.524-.33-1.07-.602-1.657-.695-.651-.105-1.496-.044-2.16.022-.85.084-.668-.142-1.448-.533-.498-.248-1.162-.05-1.514-.052a4.1 4.1 0 01-.61-.037 4.531 4.531 0 01-1.054-.346c-.213-.088-.12-.265-.34-.334-.11-.035-.569-.01-.74-.08-.448-.181-1.005-.406-1.314-.351a.805.805 0 00-.425.27.677.677 0 01-.425.256c-.193.014-.646-.23-.946-.393a7.558 7.558 0 00-.222-.118 4.07 4.07 0 01-.466-.273c0-.01 0-.023-.01-.033l-.026.009a9.105 9.105 0 01-.997-.827 5.754 5.754 0 00-2.223-1.29c.522-.072.958-.321 1.407-.581a5.347 5.347 0 011.196-.562c.512-.151 1.097-.154 1.664-.158.599 0 1.218-.006 1.76-.185.563-.187.987-.593 1.398-.986.432-.415.969-.479 1.578-.64.486-.128 1.103-.536 1.85-.582.926-.055 1.883-.114 2.492-.347.348.439.452 1.06.485 1.721v.008c.013.267.015.538.017.808 0 .622.007 1.21.144 1.68.25.857.76 1.287 1.406 1.828zm-19.322-2.542a11.574 11.574 0 01-.35-2.828c-.013-1.006.091-2.01.312-2.99a9.372 9.372 0 001.368-.523c.384-.198.68-.263.971-.573.162-.18.333-.351.512-.512.713-.606 1.364-.524 2.048-.436.164.022.33.041.5.053.227.184.43.101.608.256.834.718 1.332 1.45 2.853 1.45 1.103 0 1.962.012 2.834.535a11.944 11.944 0 01.762.502l.149.106c.488.349.949.679 1.589.782.218.03.439.04.659.029.42-.008.786-.016 1.103.31-.587.208-1.514.268-2.41.322-.753.046-1.465.09-1.962.22-.64.17-1.102.61-1.548 1.037-.419.4-.813.778-1.35.956-.535.178-1.13.177-1.72.18-.588.002-1.164.007-1.697.162-.43.14-.84.332-1.222.573-.548.316-1.074.617-1.763.58a6.857 6.857 0 00-.599-.113l-.056-.016v.009a9.988 9.988 0 00-1.591-.07z"
      ></path>
      <path
        fill="#041333"
        d="M39.378 28.123c-.22-2.176-2.102-2.894-4.48-3.089l-.01-3.018-1.837.01.015 2.944c-.483 0-.977.014-1.467.027l-.015-2.958-1.836.01.016 3.017c-.398.01-.789.02-1.17.022v-.009l-2.534.01.01 1.961s1.358-.033 1.334-.007c.745 0 .99.426 1.063.798l.018 3.438c.065 0 .13.005.194.013h-.194l.026 4.816a.65.65 0 01-.688.611c.023.022-1.335.008-1.335.008l-.353 2.197 2.39-.013h1.313l.017 3.051 1.834-.009-.016-3.02c.503.007.991.009 1.467.006l.016 3.005 1.836-.009-.015-3.046c3.087-.195 5.248-.984 5.498-3.885.204-2.336-.896-3.372-2.655-3.785 1.061-.545 1.723-1.504 1.558-3.096zm-2.535 6.54c.013 2.28-3.894 2.048-5.139 2.048l-.02-4.044c1.244-.004 5.146-.383 5.16 1.995zm-.884-5.7c.01 2.075-3.249 1.85-4.286 1.856l-.019-3.669c1.037-.005 4.292-.352 4.305 1.813z"
      ></path>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_21099_151256"
        x1="33.28"
        x2="33.28"
        y1="44.832"
        y2="19.168"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#603211"></stop>
        <stop offset="0.17" stopColor="#DD7D26"></stop>
        <stop offset="0.35" stopColor="#603211"></stop>
        <stop offset="0.54" stopColor="#9C3520"></stop>
        <stop offset="0.77" stopColor="#DD7D26"></stop>
        <stop offset="1" stopColor="#603211"></stop>
      </linearGradient>
      <clipPath id="clip0_21099_151256">
        <rect width="64" height="64" fill="#fff" rx="32"></rect>
      </clipPath>
    </defs>
  </svg>
)
